<template>
    <FcoLink class="fco-header-brand" :to="homeRoute" :href="fcoUrl(isSMSUser ? '/catalog/browse.html' : '/')">
        <picture v-if="!isLocaleSpanish">
            <source
                type="image/webp"
                media="(max-width: 767px)"
                srcset="/images/en/oreillypro-logo-white-notag-260.webp, /images/en/oreillypro-logo-white-notag-520.webp 2x"
            />
            <source
                type="image/webp"
                media="(min-width: 768px)"
                srcset="/images/en/oreillypro-logo-notag-260.webp, /images/en/oreillypro-logo-notag-520.webp 2x"
            />
            <source
                type="image/png"
                media="(max-width: 767px)"
                srcset="/images/en/oreillypro-logo-white-notag-260.png, /images/en/oreillypro-logo-white-notag-520.png 2x"
            />
            <source
                type="image/png"
                media="(min-width: 768px)"
                srcset="/images/en/oreillypro-logo-notag-260.png, /images/en/oreillypro-logo-notag-520.png 2x"
            />
            <source type="image/png" srcset="/images/en/oreillypro-logo-notag-260.png" />
            <img class="qaa-header-logo" src="/images/en/oreillypro-logo-notag.png" :alt="fcoM['rs.homeMain.fcoLogo']" />
        </picture>
        <picture v-else class="spanish-logo">
            <source
                type="image/webp"
                media="(max-width: 767px)"
                srcset="/images/es/oreillypro-logo-white-notag-260.webp, /images/es/oreillypro-logo-white-notag-520.webp 2x"
            />
            <source
                type="image/webp"
                media="(min-width: 768px)"
                srcset="/images/es/oreillypro-logo-notag-260.webp, /images/es/oreillypro-logo-notag-520.webp 2x"
            />
            <source
                type="image/png"
                media="(max-width: 767px)"
                srcset="/images/es/oreillypro-logo-white-notag-260.png, /images/es/oreillypro-logo-white-notag-520.png 2x"
            />
            <source
                type="image/png"
                media="(min-width: 768px)"
                srcset="/images/es/oreillypro-logo-notag-260.png, /images/es/oreillypro-logo-notag-520.png 2x"
            />
            <source type="image/png" srcset="/images/es/oreillypro-logo-notag-260.png" />
            <img class="qaa-header-logo" src="/images/es/oreillypro-logo-notag.png" :alt="fcoM['rs.homeMain.fcoLogo']" />
        </picture>
    </FcoLink>
</template>

<script>
import { mapGetters } from 'vuex';
import FcoLink from '../FcoLink.vue';

export default {
    name: 'HeaderBrandLink',
    components: { FcoLink },
    computed: {
        ...mapGetters(['isSMSUser', 'isAuthorizedUser']),
        ...mapGetters('i18n', ['isLocaleSpanish']),
        homeRoute() {
            if (!this.isAuthorizedUser) return '/login';
            if (this.isSMSUser) return '/catalog/browse';
            return '/';
        },
    },
};
</script>
<style scoped lang="scss">
.spanish-logo {
    max-width: 87%;
    margin-left: 11px;
}
</style>
