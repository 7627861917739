var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "FcoLink",
    {
      staticClass: "fco-header-brand",
      attrs: {
        to: _vm.homeRoute,
        href: _vm.fcoUrl(_vm.isSMSUser ? "/catalog/browse.html" : "/"),
      },
    },
    [
      !_vm.isLocaleSpanish
        ? _c("picture", [
            _c("source", {
              attrs: {
                type: "image/webp",
                media: "(max-width: 767px)",
                srcset:
                  "/FirstCallOnline/images/en/oreillypro-logo-white-notag-260.webp" +
                  ", " +
                  "/FirstCallOnline/images/en/oreillypro-logo-white-notag-520.webp" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/webp",
                media: "(min-width: 768px)",
                srcset:
                  "/FirstCallOnline/images/en/oreillypro-logo-notag-260.webp" +
                  ", " +
                  "/FirstCallOnline/images/en/oreillypro-logo-notag-520.webp" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/png",
                media: "(max-width: 767px)",
                srcset:
                  "/FirstCallOnline/images/en/oreillypro-logo-white-notag-260.png" +
                  ", " +
                  "/FirstCallOnline/images/en/oreillypro-logo-white-notag-520.png" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/png",
                media: "(min-width: 768px)",
                srcset:
                  "/FirstCallOnline/images/en/oreillypro-logo-notag-260.png" +
                  ", " +
                  "/FirstCallOnline/images/en/oreillypro-logo-notag-520.png" +
                  " 2x",
              },
            }),
            _vm._v(" "),
            _c("source", {
              attrs: {
                type: "image/png",
                srcset:
                  "/FirstCallOnline/images/en/oreillypro-logo-notag-260.png",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "qaa-header-logo",
              attrs: {
                src: "/FirstCallOnline/images/en/oreillypro-logo-notag.png",
                alt: _vm.fcoM["rs.homeMain.fcoLogo"],
              },
            }),
          ])
        : _c(
            "picture",
            {
              staticClass: "spanish-logo",
            },
            [
              _c("source", {
                attrs: {
                  type: "image/webp",
                  media: "(max-width: 767px)",
                  srcset:
                    "/FirstCallOnline/images/es/oreillypro-logo-white-notag-260.webp" +
                    ", " +
                    "/FirstCallOnline/images/es/oreillypro-logo-white-notag-520.webp" +
                    " 2x",
                },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  type: "image/webp",
                  media: "(min-width: 768px)",
                  srcset:
                    "/FirstCallOnline/images/es/oreillypro-logo-notag-260.webp" +
                    ", " +
                    "/FirstCallOnline/images/es/oreillypro-logo-notag-520.webp" +
                    " 2x",
                },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  type: "image/png",
                  media: "(max-width: 767px)",
                  srcset:
                    "/FirstCallOnline/images/es/oreillypro-logo-white-notag-260.png" +
                    ", " +
                    "/FirstCallOnline/images/es/oreillypro-logo-white-notag-520.png" +
                    " 2x",
                },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  type: "image/png",
                  media: "(min-width: 768px)",
                  srcset:
                    "/FirstCallOnline/images/es/oreillypro-logo-notag-260.png" +
                    ", " +
                    "/FirstCallOnline/images/es/oreillypro-logo-notag-520.png" +
                    " 2x",
                },
              }),
              _vm._v(" "),
              _c("source", {
                attrs: {
                  type: "image/png",
                  srcset:
                    "/FirstCallOnline/images/es/oreillypro-logo-notag-260.png",
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "qaa-header-logo",
                attrs: {
                  src: "/FirstCallOnline/images/es/oreillypro-logo-notag.png",
                  alt: _vm.fcoM["rs.homeMain.fcoLogo"],
                },
              }),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }