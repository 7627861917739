// A mixin providing helper functions
import { getProductSubset } from 'fcoModules/utilities';
import featureFlagsMixin from '@/common/mixins/featureFlagsMixin';
import { Device } from '../constants/cms';

export default {
    mixins: [featureFlagsMixin],
    methods: {
        getProductSubset,
        getSpaCatalogRedirectPath(isKits = false) {
            if (!this.isEntSearchEnabled || isKits) {
                return '/catalog/products';
            }
            return '/catalog/v2/products';
        },
        getDeviceType() {
            if (this.$fcoMq.isSmDown) {
                return Device.MOBILE;
            }

            if (this.$fcoMq.isMdDown) {
                return Device.TABLET;
            }

            return Device.DESKTOP;
        },
    },
};
