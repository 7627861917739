import axios from 'axios';
import store, { state } from './store/store';
import { HttpStatus } from './constants/httpStatus';
import { ForbiddenReason } from './constants/customErrorCodes';

const isExternalRequest = ({ url = '' } = {}) => url.indexOf('http') === 0 && window.location.origin !== new URL(url, document.baseURI).origin;
const stickyHeaderEndpointWhitelist = /\/((current|session)\/(user(?!\/shops)|static|stickySessionInfo)|auth\/rest\/refresh|shop\/shopDetails)/;

function currentLanguageInterceptor(config) {
    if (isExternalRequest(config) || !state.i18n?.locale) return config;
    return {
        ...config,
        headers: {
            ...config.headers,
            'Accept-Language': state.i18n?.locale,
        },
    };
}

/**
 * Adds an 'x-sticky' header to all requests when authenticated to ensure requests go to a single pod in k8s
 * Without this, responses might include cached (and possibly incorrect) data from a different pod
 */
async function stickySessionInterceptor(config) {
    if (isExternalRequest(config)) return config;

    if (!state.stickySessionId) {
        // Need to first check for a handful of url's that we can't/don't want to even try to add x-sticky to because they will cause infinite awaiting issues
        if (stickyHeaderEndpointWhitelist.test(config.url) || !store.getters.isAuthorizedUser) return config;
        await store.dispatch('requestIfIdle', ['getStickySessionId']);
    }

    // If something goes awry with the sticky session request, don't attach the header
    if (!state.stickySessionId) return config;

    return {
        ...config,
        headers: {
            ...config.headers,
            'x-sticky': state.stickySessionId,
        },
    };
}

async function unauthorizedErrorInterceptor(error) {
    // Only handle unauthorized requests to our own API
    if (isExternalRequest(error.config) || error.response?.status !== HttpStatus.UNAUTHORIZED) return Promise.reject(error);

    const isExpiredAuthToken = state.isSPA && state.auth;
    let responseError = error;

    if (isExpiredAuthToken) {
        // Avoid an infinite loop. Did we already retry the reqeuest?
        // Make sure this error isn't the result of an attempt to retry the request.
        const isRefreshTokenRequest = error.config?.url.includes('/auth/rest/refresh');
        const isRetryRequest = !!error.config?.isRetry;
        if (!isRefreshTokenRequest && !isRetryRequest) {
            try {
                await store.dispatch('refreshAuth');

                // With our refreshed access token in hand, let's try the request again.
                return axios({
                    ...error.config,
                    headers: {
                        ...error.config?.headers,
                        Authorization: axios.defaults.headers.common.Authorization,
                    },
                    isRetry: true,
                });
            } catch (retryError) {
                responseError = retryError;
            }
        }

        // A failed request here likely means our tokens are expired, and the user is logged out.
        // Regardless of the cause, something very bad went wrong and we can't authenticate our user.
        // Time to clear out the session.
        store.dispatch('logout');
    }

    return Promise.reject(responseError);
}

/**
 * Checks every 403 error response to see if the user has restrictions placed on them for accepting terms or resetting their password.
 * The base app component can then respond to changes in these values and redirect to the corresponding page so the user can update their info.
 */
async function forbiddenErrorInterceptor(error) {
    // Only handle forbidden requests to our own API
    if (isExternalRequest(error.config) || error.response?.status !== HttpStatus.FORBIDDEN) return Promise.reject(error);

    const errorId = error.response?.data?.errorId;

    if (errorId === ForbiddenReason.ACCEPT_TERMS_REQUIRED) {
        store.commit('setTermsAcceptRequired', true);
    } else if (errorId === ForbiddenReason.PASSWORD_RESET_REQUIRED) {
        store.commit('setPasswordChangeRequired', true);
    }

    return Promise.reject(error);
}

axios.interceptors.request.use(currentLanguageInterceptor);
axios.interceptors.request.use(stickySessionInterceptor);
axios.interceptors.response.use((response) => response, unauthorizedErrorInterceptor);
axios.interceptors.response.use((response) => response, forbiddenErrorInterceptor);

axios.defaults.baseURL = __FCO_API_URL__;
