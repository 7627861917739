<template>
    <footer>
        <div class="content py-5">
            <div class="footer-grid d-print-none mb-5">
                <div class="footer-logo-container">
                    <picture v-if="!isLocaleSpanish" class="footer-logo">
                        <source type="image/webp" srcset="/images/en/oreillypro-logo-white-208.webp, /images/en/oreillypro-logo-white-416.webp 2x" />
                        <source type="image/png" srcset="/images/en/oreillypro-logo-white-208.png, /images/en/oreillypro-logo-white-416.png 2x" />
                        <source type="image/png" srcset="/images/en/oreillypro-logo-white-208.png" />
                        <img class="footer-logo" src="/images/en/oreillypro-logo-white.png" />
                    </picture>
                    <picture v-else class="footer-logo spanish-logo">
                        <source type="image/webp" srcset="/images/es/oreillypro-logo-white-208.webp, /images/es/oreillypro-logo-white-416.webp 2x" />
                        <source type="image/png" srcset="/images/es/oreillypro-logo-white-208.png, /images/es/oreillypro-logo-white-416.png 2x" />
                        <source type="image/png" srcset="/images/es/oreillypro-logo-white-208.png" />
                        <img class="footer-logo" src="/images/es/oreillypro-logo-white.png" />
                    </picture>
                </div>
                <nav>
                    <DotCmsMenu name="site-footer" class="footer-nav" :placeholderMaxWidth="150" :placeholderMinWidth="100" />

                    <ul class="footer-nav">
                        <li v-if="userCanViewStatements">
                            <FcoLink
                                class="qaa-footer-billing-statements"
                                to="/admin/statements"
                                :href="fcoUrl('/statementlookup/statementview.html')"
                            >
                                <span v-if="isEarnbackEligible"> {{ fcoM['common.statementsAndEarnbacks'] }}</span>
                                <span v-else> {{ fcoM['common.billingStatements'] }}</span>
                            </FcoLink>
                        </li>
                        <template v-if="isAuthorizedUser">
                            <li>
                                <DotCmsLink file-name="first-call-drop-box-slip.pdf" class="qaa-footer-drop-box-slip" target="_blank">
                                    {{ fcoM['rs.home.dropBoxSlip'] }}
                                </DotCmsLink>
                            </li>
                            <li>
                                <DotCmsLink file-name="FCO-INSPECTION_FORM.pdf" class="qaa-footer-vehicle-inspection-form" target="_blank">
                                    {{ fcoM['rs.home.vehicleInspectionForm'] }}
                                </DotCmsLink>
                            </li>
                            <li v-if="userCanViewLaborClaims">
                                <FcoLink class="qaa-footer-labor-claims" to="/admin/labor-claims" :href="fcoUrl('/admin/laborClaims/')">
                                    {{ fcoM['common.laborClaims'] }}
                                </FcoLink>
                            </li>
                        </template>
                    </ul>
                </nav>
                <div class="text-right">
                    <LanguageSelectButton button-class="footer-language-select" label-class="footer-language-select-label" />
                </div>
            </div>
            <div class="text-xs footer-legal">
                <div class="med-gray mb-3">{{ copyrightString }}<br />{{ versionString }}</div>
                <div class="footer-legal-nav d-print-none">
                    <FcoLink class="qaa-footer-terms-conditions" to="/terms" :href="fcoUrl('/legal/terms/index.html')">{{
                        fcoM['common.termsAndConditions']
                    }}</FcoLink>
                    <FcoLink class="qaa-footer-privacy-policy" to="/privacy" :href="fcoUrl('/legal/policy/index.html')">
                        {{ fcoM['common.privacyPolicy'] }}
                    </FcoLink>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { stringFormat } from '../../fcoModules/utilities';
import LanguageSelectButton from './LanguageSelectButton.vue';
import FcoLink from './FcoLink.vue';
import DotCmsLink from './cmsContent/DotCmsLink.vue';
import DotCmsMenu from './cmsContent/DotCmsMenu.vue';
import featureFlagsMixin from '../mixins/featureFlagsMixin';

export default {
    name: 'Footer',
    mixins: [featureFlagsMixin],
    computed: {
        ...mapGetters(['isAuthorizedUser', 'userCanViewStatements', 'userCanViewLaborClaims']),
        ...mapGetters('i18n', ['isLocaleSpanish']),
        ...mapState({
            user: (state) => state.user,
            requests: (state) => state.requests,
            version: (state) => state.version,
            isEarnbackEligible: (state) => state.isEarnbackEligible,
        }),
        copyrightString() {
            return stringFormat(this.fcoM['footer.copyright'], new Date().getFullYear());
        },
        versionString() {
            if (!this.version) return '';
            const node = this.version.node ? `(${this.version.node})` : '';
            return `v. ${this.version.fcoVersion}${node}, OCAT:${this.version.ocatVersion}`;
        },
    },
    created() {
        this.$store.dispatch('requestIfIdle', ['getAppData']);
        if (this.isAuthorizedUser) this.$store.dispatch('requestIfIdle', ['getUser', 'getIsEarnbackEligible']);
    },
    components: { LanguageSelectButton, FcoLink, DotCmsLink, DotCmsMenu },
};
</script>

<style lang="scss" scoped>
@import '~scssVariables/config';
@import '~scssVariables/mixins';

footer {
    height: auto;
    background-color: $black;
}

::v-deep .footer-nav {
    flex-wrap: wrap;
    align-items: baseline;
    gap: 1rem 2rem;
    list-style: none;
    margin: 0 0 1rem 0;
    padding: 0;

    li {
        margin: 0 0 1rem;
    }

    @include respond(sm) {
        display: flex;

        li {
            margin: 0;
        }
    }

    @include respond(md) {
        justify-content: center;
    }
}

::v-deep .footer-nav a,
.footer-legal a {
    color: $white;

    &:not(:is(:hover, :focus)) {
        text-decoration: none;
    }
}

.footer-grid {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 2rem 3rem;

    @media print {
        display: none;
    }

    @include respond(md) {
        grid-template-columns: 212px 1fr 212px;
    }
}

.footer-logo-container {
    @include respond-up-to(md) {
        grid-row: 2;
        grid-column-end: span 2;
    }
}

.footer-logo {
    width: 208px;
}

.footer-legal {
    @include respond(md) {
        text-align: center;
    }
}

.footer-legal-nav {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1rem;
    justify-content: flex-start;

    @include respond(md) {
        justify-content: center;
    }
}

::v-deep .footer-language-select {
    border: 1px solid $gray-500;
    background-color: transparent;
    color: $gray-300;
    font-weight: normal;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    .footer-language-select-label {
        color: $gray-550;
        transition: inherit;
    }

    &:hover,
    &:active,
    &.active,
    &:focus-visible {
        color: #000;
        background-color: $gray-300;
        border-color: $gray-300;

        .footer-language-select-label {
            color: $gray-700;
        }
    }

    &:active,
    &:focus-visible,
    &.active {
        box-shadow: 0 0 0 2px rgba(242, 242, 242, 0.5);
    }
}
.spanish-logo {
    max-width: 87%;
    margin-left: 11px;
}
</style>
