import { request as graphQLRequest, gql } from 'graphql-request';
import { isPlainObject } from 'fco/dist/utils/types';
import { Locale } from '../constants/user';
import { getDotCMSLanguageIdFromLocale } from '../utilities/cmsUtilities';
import store, { state } from '../store/store';

/**
 * Returns the provided dotCMS search results where English and i18n versions are merged together, falling back to English where needed
 */
const handleI18nSearchResults = (results) => {
    const finalResults = [];

    // Keep track of each result's position in the array so we can look it up later and swap it out for i18n content.
    const finalResultIndexById = new Map();

    // EN results are used for tracking sort order, even if the locale is switched. This ensures consistent sorting between languages.
    // This map allows us to set i18n results off to the side and come back to them later
    const i18nContentById = new Map();

    // Relational content within results (i.e. featured image, parent/child content, etc) may not be translated.
    // In that case, we need to fall back to English. To do that, we target any keys on the result object that contain relational content.
    // Caching that list of keys per content type improves performance by avoiding duplicate Object.keys() calls.
    const relationalContentKeysByContentType = new Map();

    // For each item in the list, look for i18n content. If none, fall back to the English version.
    results.forEach((result) => {
        const {
            identifier,
            conLanguage: { languageCode },
            contentType,
        } = result;

        const isEnResult = languageCode === Locale.EN;
        const i18nResult = !isEnResult ? { ...result } : i18nContentById.get(identifier);

        if (isEnResult && !i18nResult) {
            // We haven't tracked this English item yet. Put it in the list as is, and track its position.
            // If we find a corresponding i18n result later, we'll circle back to it and replace it.
            finalResultIndexById.set(identifier, finalResults.push({ ...result }) - 1);
            return;
        }

        const resultIndex = finalResultIndexById.get(identifier);

        if (!isEnResult && resultIndex === undefined) {
            // We have i18n content without a corresponding English version in the final array.
            // Put it aside for now so we can circle back to it later if/when we find the English version.
            i18nContentById.set(identifier, { ...result });
            return;
        }

        const enResult = isEnResult ? { ...result } : finalResults[resultIndex];
        const cachedRelationalContentKeys = contentType ? relationalContentKeysByContentType.get(contentType) : undefined;
        const relationalContentKeys =
            cachedRelationalContentKeys ??
            Object.keys(enResult).filter((key) => {
                const value = enResult[key];
                return isPlainObject(value) || (Array.isArray(value) && value[0]?.identifier);
            });

        if (contentType && !cachedRelationalContentKeys) {
            // Cache the keys for this content type that contain relational content
            relationalContentKeysByContentType.set(contentType, relationalContentKeys);
        }

        relationalContentKeys.forEach((key) => {
            const i18nResultKeyValue = i18nResult[key];
            const enResultKeyValue = enResult[key];

            if (!Array.isArray(enResultKeyValue)) {
                // We're working with a single related item. If the i18n version is null, simply assign the English value to it.
                if (!i18nResultKeyValue) {
                    i18nResult[key] = enResultKeyValue;
                }
                return;
            }

            // Map the English version of each related item to use the corresponding i18n version if one exists.
            const i18nChildContentById = i18nResultKeyValue.reduce((mappedChildContent, childContent) => {
                mappedChildContent[childContent.identifier] = childContent;
                return mappedChildContent;
            }, {});
            i18nResult[key] = enResultKeyValue.map((enChild) => i18nChildContentById[enChild.identifier] || enChild);
        });

        if (resultIndex === undefined) {
            // Most likely this means we have an EN result where we found a corresponding i18n result that we already tracked
            // Just add it to the array
            finalResults.push(i18nResult);
        } else {
            finalResults.splice(resultIndex, 1, i18nResult);
        }
    });

    return finalResults;
};

/**
 *
 * @param {Object} options
 * @param {String} options.contentType The name of the content type in dotCMS
 * @param {String} options.fields A GraphQL fields object
 * @param {String} options.query Manually specify a dotCMS content query
 * @param {String} options.locale A valid locale we support - defaults to 'en'
 * @returns
 */
export const searchDotCMS = async ({ contentType, fields = '', query = '', locale = Locale.EN } = {}) => {
    await store.dispatch('requestIfIdle', ['getCMSData']);

    let finalQuery = query;
    let finalFields = fields;
    let finalHeaders;

    if (contentType) {
        finalQuery += ` +contentType:${contentType}`;
        finalFields = gql`
            ...on ${contentType} ${finalFields}
        `;
    }

    const hasCustomLanguageQuery = query.includes('languageId');

    // If your query override contains a languageId, you must know what you're doing...
    // No need to specify a language if you already have.
    if (!hasCustomLanguageQuery) {
        // Always include English results so we can fall back to the English version of any content that hasn't been translated to the requested language
        const languageIds = new Set([Locale.EN, locale].map(getDotCMSLanguageIdFromLocale));
        finalQuery += ` +languageId:(${Array.from(languageIds).join(' || ')})`;
    }

    const isI18nRequest = hasCustomLanguageQuery || locale !== Locale.EN;

    if (isI18nRequest) {
        // If we're asking for something other than English, we need to make sure results have an identifier and language so we can map them appropriately
        finalFields += gql`
            ...on DotContentlet {
                identifier
                contentType
                conLanguage {
                    id
                    languageCode
                    countryCode
                }
            }
        `;
    }

    if (state.cmsData.token) {
        finalHeaders = { authorization: `Bearer ${state.cmsData.token}` };
    }

    const request = gql`
        query {
            search(query: "${finalQuery}") {
                ${finalFields}
            }
        }
    `;
    const response = await graphQLRequest(`${state.cmsData.url}/api/v1/graphql`, request, null, finalHeaders);

    if (!isI18nRequest) {
        return response.search;
    }

    return handleI18nSearchResults(response.search);
};
