import store, { state } from '../store/store';

const ACCOUNT_ID = 200106309927;
const SCRIPT_SRC_URL = '//ee.channels.ocs.oraclecloud.com/js/eesvcs.js';
const CHAT_READY = 'chatReady';
const CHAT_DISABLED = 'chatDisabled';
let bodyObserver = null;

const hideChatButton = () => {
    const $button = document.querySelector('.wcfs-wrapper .wcfs-button');

    $button?.classList.add('d-none');
};
const onHideButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();

    hideChatButton();
    store.commit('setIsChatButtonHidden', true);
};
const bodyMutated = () => {
    const $hideButton = document.querySelector('#chatHideButtonButton');

    if ($hideButton) {
        if (state.isChatButtonHidden) {
            hideChatButton();
        } else {
            $hideButton.addEventListener('click', onHideButtonClick);
        }

        bodyObserver.disconnect();
    }
};
const onOracleScriptLoaded = () => {
    window.EESvcs.start();
};
const initEvents = () => {
    window.addEventListener(CHAT_READY, () => {
        const $body = document.querySelector('body');

        bodyObserver = new MutationObserver(bodyMutated);
        bodyObserver.observe($body, { childList: true, subtree: true });
        store.commit('setIsChatReady', true);
    });
    window.addEventListener(CHAT_DISABLED, () => {
        store.commit('setIsChatDisabled', true);
    });

    /* exposes functions for Oracle script to call */
    window.dispatchChatReadyEvent = () => {
        window.dispatchEvent(new Event(CHAT_READY));
    };
    window.dispatchChatDisabledEvent = () => {
        window.dispatchEvent(new Event(CHAT_DISABLED));
    };
};
const createScript = () => {
    const oracleScript = document.createElement('script');

    oracleScript.src = SCRIPT_SRC_URL;
    oracleScript.type = 'application/javascript';
    oracleScript.async = true;
    oracleScript.defer = true; // fallback support for browsers that does not support async
    oracleScript.onload = onOracleScriptLoaded;

    document.head.appendChild(oracleScript);
};
export const openChat = () => {
    /*
    Oracle EE is monitoring this variable for changes,
    and when it changes the 'open chat' behavior is triggered.
    */
    window.chatButtonClickedFlag *= -1;
};
export const initOracleChat = () => {
    window.EESvcs = { eeid: ACCOUNT_ID };
    window.chatButtonClickedFlag = 1;

    initEvents();
    createScript();
};
