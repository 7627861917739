import { render, staticRenderFns } from "./HeaderBrandLink.vue?vue&type=template&id=700c7eed&scoped=true&"
import script from "./HeaderBrandLink.vue?vue&type=script&lang=js&"
export * from "./HeaderBrandLink.vue?vue&type=script&lang=js&"
import style0 from "./HeaderBrandLink.vue?vue&type=style&index=0&id=700c7eed&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700c7eed",
  null
  
)

export default component.exports